@import "../../scss/colors.scss";

.home {
  &__box {
    display: flex;
  }
  &__header {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.3px;
    line-height: 30px;
    color: #252733;
    text-transform: uppercase;

    margin-bottom: 54px;
  }
  &__left {
    width: 200px;
    min-height: 100vh;
    background-color: $gray;
  }

  &__menuLogo {
    display: flex;
    align-items: center;

    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    color: #000;
    padding: 0 20px;

    img {
      min-width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }

  &__menu {
    padding: 30px 0;
    ul {
      margin-top: 40px;
    }
    li a {
      display: flex;
      align-items: center;
      height: 56px;
      width: 100%;
      padding: 0 32px;

      font-size: 16px;
      line-height: 20px;
      color: #a4a6b3;

      transition: all 0.2s ease;
      &:hover {
        color: #dde2ff;
        background-color: rgba(#9fa2b4, 0.08);
        svg {
          path {
            fill: #fff;
          }
        }
      }

      &.active {
        color: #000;
        background-color: rgba(#9fa2b4, 0.08);
        svg {
          path {
            fill: #000;
          }
        }
      }
      svg {
        margin-right: 24px;
        path {
          transition: all 0.2s ease;
          fill: #9fa2b4;
        }
      }
    }
  }

  &__right {
    width: calc(100% - 225px);
    padding: 30px;
    background-color: $skyBlue;
  }

  &__rightInner {
    width: 1320px;
  }
}
