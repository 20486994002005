.main_wrapper {
  width: 100%;
  min-height: 100px;
  margin-top: 10px;
  padding-left: 30px;
  display: flex;
  justify-self: flex-start;
  padding-top: 10px;
  border-bottom: 1px solid #dfe0eb;
  padding-bottom: 20px;
  height: 300px;
}
.name {
  width: 200px;
  margin-right: 70px;
}
.categories {
  width: 120px;
  margin-right: 40px;
  p {
    margin-top: 5px;
  }
}

.description {
  width: 390px;
  padding-top: 5px;
}
.ingridients {
  width: 160px;
  margin-left: 70px;
}
.ingridients_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  img {
    width: 24px;
    height: 24px;
    margin-top: 5px;
  }
}
.icon_container {
  width: 100px;
  margin-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 36px;
    height: 36px;
  }
}
.options_container {
  width: 150px;
  margin-left: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    cursor: pointer;
    width: 36px;
    height: 36px;
  }
}

.checkBox_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  margin-bottom: 5px;
  p {
    margin: 0;
    font-size: 14px;
  }
  input{
    cursor: pointer;
  }
}
.ingridientsChange{
  width: 190px;
  margin-left:20px;
}
.ingridient_description_area{
  margin-bottom: 10px;
}