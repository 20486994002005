@import "../../scss/repeat.scss";
@import "../../scss/colors.scss";

.button {
  @extend %button;

  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: $red;

  transition: all 0.2s ease;
  &:hover {
    background-color: darken($red, 10%);
  }
}
