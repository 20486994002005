.overview {
  width: 1000px;
  &__case {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__statistic {
    background: #ffffff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;

    display: flex;

    margin-top: 30px;

    &_right {
      width: 100%;
      border-left: 1px solid #dfe0eb;
    }
  }

  &__static {
    width: 100%;
    height: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
      border-bottom: 1px solid #dfe0eb;
    }

    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.3px;
      color: #9fa2b4;

      margin-bottom: 6px;
    }
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.3px;
      color: #252733;
    }
  }
}
