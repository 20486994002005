@import "../../scss/colors.scss";
@import "../../scss/repeat.scss";

.formInput {
  @extend %input;

  width: 100%;
  height: 100%;

  font-size: 18px;
  padding: 10px;
  border-radius: 6px;

  &::placeholder {
    font-family: "Mulish";
    font-size: 16px;
  }
}
.warning{
 

  width: 100%;
  height: 100%;

  font-size: 18px;
  padding: 10px;
  border-radius: 6px;

  &::placeholder {
    font-family: "Mulish";
    font-size: 16px;
  }


}
