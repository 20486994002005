.category {
  &__input_file {
    display: none;
  }
  &__form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__item {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #dfe0eb;
    padding: 10px 10px 10px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease;
    height: 150px;
    &:hover {
      background-color: darken(#fff, 4%);
    }
  }
  &__setting {
    border: none;
    outline: none;
    position: absolute;
    right: 30px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.2s ease;
    &:hover {
      background-color: darken(#fff, 6%);
    }
  }
  &__nameContainer {
    width: 133px;
    display: flex;
    justify-content: center;
    align-items: center;

    textarea {
      width: 100%;
      text-align: center;
    }
  }

  &__description {
    width: 500px;
    textarea {
      width: 100%;
    }
  }
  &__imgBox {
    width: 100px;

    img {
      width: 56px;
      height: 56px;
    }
  }

  &__edit {
    width: 100px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    img {
      width: 30px;
      height: 30px;
      margin: 0;
      cursor: pointer;
    }
  }
  &__trash {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
  // &__edit {
  //   position: relative;
  //   bottom: 25px;
  //   right: 5px;
  //   width: 20px;
  //   height: 20px;
  // }
  &__delete {
    display: flex;
    position: absolute;
    right: 87px;
    border: none;
    outline: none;
    background: transparent;
  }
  &__confirm {
    display: flex;
    position: absolute;
    right: 140px;
    border: none;
    outline: none; /* Для синий ободки */
    background: transparent;
    transition: ease 0.3s all;
    &:active {
      opacity: 0.3;
    }
  }
  &__deleteIcon {
    width: 25px;
    height: 25px;
  }
  &__error {
    position: absolute;
    margin-top: 5px;
    color: #fa7268;
    font-size: 13px;
    width: 100%;
    text-align: center;
  }
  &__fl_replace {
    width: 210px;
    padding-right: 130px;
  }
}
