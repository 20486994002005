.main_container {
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 50px;
  width: 320px;
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  padding-left: 5px;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.7;
  }
}

.modalDelete {
  position: fixed;
  z-index: 50;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(#000, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;

  &__form {
    width: 400px;
    height: 300px;
    border-radius: 30px;
    background-color: #fff;
  }
}
