@import "../../scss/colors.scss";

.signIn {
  background-image: url("../../assets/login_image.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  // background-color: #BCDDF5;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    width: 400px;
    min-height: 200px;
    border-radius: 20px;
    padding: 40px 20px;

    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(20px);
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    color: #000;

    text-align: center;
    margin-bottom: 30px;
  }

  &__input {
    width: 100%;
    height: 46px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__btn {
    margin: 30px auto 0;
    width: 180px;
    height: 48px;

    span {
      font-weight: 600;
      font-size: 18px;
      color: #fff;
    }
  }
}
