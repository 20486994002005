.main {
  // background-color: green;

  border: 1px solid black;
  border-radius: 6px;
  width: 100%;
  height: 106px;
  margin-top: 10px;
  padding-top: 5px;
  .img_box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-left: 3px;
  }
  textarea {
    width: 100%;
    height: 70px;
    margin-top: 5px;
    padding-left: 5px;
    padding-top: 5px;
    outline: none;
    resize: none;
  }
}

.img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.img_ingridient {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.confirm_box {
  display: flex;
  align-items: center;
}
.warning {
  border-color: red;
  &::placeholder {
    color: red;
  }
}
text{
  border-color: #000;
  &::placeholder {
    color: #ccc;
  }
}
