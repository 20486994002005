.category {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  width: 1010px;
  &__loader {
    margin-top: 54px;
    display: flex;
    justify-content: center;
  }
  &__selectedIcon {
    width: 40px;
    height: 40px;
  }
  &__select {
    border-radius: 8px;
    width: 200px;
    height: 40px;
  }
  &__fl_upld {
    position: relative;
    transition: ease 0.3s all;
  }
  &__text_choose_icon {
    text-align: center;
    width: 100px;
    cursor: pointer;
  }

  &__uploadIcon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  &__error {
    position: absolute;
    margin-top: 10px;
    color: #fa7268;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
  &__from_input {
    height: 50px;
    border-radius: 5px;
    border-width: 1px;
    border: 1px solid hsl(0, 0%, 70%);
    padding: 3px 8px 3px 8px;

    &:focus {
      outline: none;
      border-width: 2px;
      border-color: #2684ff;
    }
  }
  &__from_input2 {
    height: 120px;
    width: 300px;
    margin-left: 10px;
    // margin-right: 20px;
    border-radius: 5px;
    border-width: 1px;
    border: 1px solid hsl(0, 0%, 70%);
    padding: 5px 0 5px 5px;

    &:focus {
      outline: none;
      border-width: 2px;
      border-color: #2684ff;
    }
  }

&__check_box_main_wrapper{
  width: 200px;
}
&__check_box_wrap{
  margin-right:  10px;
  margin-top:6px;
  cursor: pointer;
}

  &__ingridients_main_box {
    width: 300px;
    min-height: 40px;
  }
  &__search_ingridient {
    width: 260px;
    height: 40px;
    border-radius: 6px;
    padding-left: 6px;
  }
  &__show {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  &__show_btn {
    width: 80px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
  &__form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__inputChoose {
    display: none;
  }
  &__start {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 32px 140px;
  }
  &__title {
    cursor: pointer;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4b506d;
    border: 0;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    transition: ease 0.1s all;
    height: 50px;
    &:active {
      opacity: 0.3;
    }
  }
  &__tools {
    display: flex;
    align-items: center;
  }
  &__tool {
    display: flex;
    align-items: center;

    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #4b506d;

    &:not(:last-child) {
      margin-right: 32px;
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  &__titleTable {
    border-bottom: 1px solid #dfe0eb;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px;

    &_items {
      width: 84%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // padding-right: 30px;
      // background-color: lightgreen;
      p {
        // border: 1px solid black;
        width: 390px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #9fa2b4;
        // background-color: red;
      }
      div{
        // background-color: magenta;
        // border: 1px solid black;
        width: 60px;
        // margin-right: 30px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #9fa2b4;
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 27px 32px;

    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.3px;
      color: #9fa2b4;

      margin-right: 24px;
    }
  }
  &__arrows {
    display: flex;
    align-items: center;
  }
  &__arrow {
    width: 24px;
    height: 24px;

    cursor: pointer;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  &__chosen_products_box {
    margin-top: 40px;
    width: 320px;
  }
  &__label{
    background-color: #dfe0eb;
    border:1px solid #000;
    border-radius: 6px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: opacity .3s;
    transition: background-color .3s;
    cursor: pointer;
    height:100px;
    margin-right: 70px;
    &:hover{
      opacity: 0.8;
      
    }
  }
  &__selectedIcon{
    width: 50px;
    height: 50px;
  }
  &__img_picker_box {
    margin-top: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
  }
  &__selectedIcon{
    margin:0 auto;
  }
  &__show_more{
    margin-left: 20px;
    background-color: #e77340;
    width: 100px;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity .3s;
    &:hover{
      opacity: 0.8;
    }
  }
}
