.modalDelete{
    position: fixed;
    z-index: 50;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(#000, 0.4);
  
    display: flex;
    align-items: center;
    justify-content: center;
    
  
    &__form2{
      width: 320px;
      height: 200px;
      border-radius: 30px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      z-index: 500;
    }
    &__imgBox{
      width: 100%;
      height: 50px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right:20px;
    }
    img{
      width: 30px;
      height: 30px;
      cursor: pointer;
     
    }
    .save_container{
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .save_img{
      width: 50px;
      height: 50px;
      margin:0 auto;
    }
  }
