@import "../../scss/colors.scss";

.case {
  &__item {
    width: 260px;
    height: 135px;
    background-color: #fff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      font-weight: 700;
      font-size: 19px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #9fa2b4;
      transition: all 0.2s ease;

      margin-bottom: 12px;
    }

    h2 {
      font-weight: 700;
      font-size: 40px;
      letter-spacing: 1px;
      color: #252733;
      transition: all 0.2s ease;
    }

    transition: all 0.2s ease;
    &:hover {
      border-color: $red;
      box-shadow: 1px 10px 16px -1px rgba(0, 0, 0, 0.2);
      h2 {
        color: $red;
      }
      h3 {
        color: $red;
      }
    }
  }
}
