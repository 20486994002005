.category {
  background: #ffffff;
  width: 1000px;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  &__loader {
    margin-top: 54px;
    display: flex;
    justify-content: center;
  }
  &__selectedIcon {
    width: 40px;
    height: 40px;
  }
  &__select {
    border-radius: 8px;
    width: 200px;
  }
  &__fl_upld {
    position: relative;
    transition: ease 0.3s all;
  }
  &__text_choose_icon {
    text-align: center;
    width: 100px;
    cursor: pointer;
  }
  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #dfe0eb;
    border-radius: 8px;
    width: 130px;
    &:active {
      opacity: 0.3;
    }
  }
  &__uploadIcon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  &__error {
    position: absolute;
    margin-top: 10px;
    color: #fa7268;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
  &__from_input {
    height: 50px;
    border-radius: 5px;
    border-width: 1px;
    border: 1px solid hsl(0, 0%, 70%);
    padding: 3px 8px 3px 8px;

    &:focus {
      outline: none;
      border-width: 2px;
      border-color: #2684ff;
    }
  }
  &__from_input2 {
    height: 120px;
    width: 300px;
    border-radius: 5px;
    border-width: 1px;
    border: 1px solid hsl(0, 0%, 70%);
    padding: 5px 0 5px 5px;
    // vertical-align: top;
    // padding: 0 28px 3px 28px;

    &:focus {
      outline: none;
      border-width: 2px;
      border-color: #2684ff;
    }
  }
  &__form {
    width: 100%;
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: space-around;
  }
  &__inputChoose {
    display: none;
  }
  &__start {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 32px 32px 48px;
  }
  &__title {
    cursor: pointer;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #4b506d;
    border: 0;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    transition: ease 0.1s all;
    height: 50px;
    &:active {
      opacity: 0.3;
    }
  }
  &__tools {
    display: flex;
    align-items: center;
  }
  &__tool {
    display: flex;
    align-items: center;

    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #4b506d;

    &:not(:last-child) {
      margin-right: 32px;
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  &__titleTable {
    border-bottom: 1px solid #dfe0eb;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px;

    &_items {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 30px;

      p {
        width: 210px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #9fa2b4;
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 27px 32px;
    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.3px;
      color: #000;
    }
  }
  &__showMoreContainer {
    background-color: rgba(231, 115, 64, 1);
    margin-left: 30px;
    width: 100px;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
  &__arrows {
    display: flex;
    align-items: center;
  }
  &__arrow {
    width: 24px;
    height: 24px;

    cursor: pointer;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  &__errorDeck {
    position: absolute;
    margin-top: 5px;
    color: #fa7268;
    font-size: 13px;
    text-align: center;
    width: 300px;
  }
}
