%button {
  display: inline-block;
  outline: none;
  border: none;
  cursor: pointer;
}

%input {
  outline: none;
  border: none;
}

%center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
